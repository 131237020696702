.hovered-text {
    z-index: 99;
    top: -1rem;
}

.border-cover {
    z-index: 98;
}

.tabs-container {
    top: -1rem
}
