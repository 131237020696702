.image-hover-highlight {
    cursor: pointer;
}

.image-hover-highlight:hover {
    border: 2px solid #1DE282;
    filter: brightness(130%);
    -webkit-filter: brightness(130%);
    -moz-filter: brightness(130%);
    -o-filter: brightness(130%);
    -ms-filter: brightness(130%);
    transition: all 0.50s;

}
